<template>
  <div class="container">
    <a-page-header title="外包记录" :backIcon="false"></a-page-header>
    <div class="wrapper">
      <a-form layout="inline" :form="form">
        <a-form-item label="创建时间">
          <a-range-picker v-decorator="['timeArr']" />
        </a-form-item>
        <a-form-item label="项目名称">
          <a-input type="text" v-decorator="['task_name']" />
        </a-form-item>
        <a-form-item label="公司名称" v-if="userInfo.role_id == 2 || userInfo.role_id == 0">
          <a-select style="width: 170px" v-decorator="['user_id']" :allowClear="true">
            <a-select-option :value="item.id" v-for="item in userRole1" :key="item.id">{{item.company_name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="服务商名称" v-if="userInfo.role_id == 1 || userInfo.role_id == 0">
          <a-select style="width: 170px" v-decorator="['company_id']" :allowClear="true">
            <a-select-option :value="item.id" v-for="item in userRole2" :key="item.id">{{item.real_name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="状态">
          <a-select style="width: 170px" v-decorator="['status']" :allowClear="true">
            <a-select-option :value="0">已创建项目</a-select-option>
            <a-select-option :value="1">已接项目</a-select-option>
            <a-select-option :value="2">已完成项目</a-select-option>
            <a-select-option :value="3">公司已支付</a-select-option>
            <a-select-option :value="4">服务商已收款</a-select-option>
            <a-select-option :value="5">公司已申请开票</a-select-option>
            <a-select-option :value="6">服务商已开票</a-select-option>
            <a-select-option :value="7">服务商已付款到分包商</a-select-option>
            <a-select-option :value="8">分包商已收款并开票</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="handleSearch">搜索</a-button>
          <a-button style="margin-left:5px;" @click="handleReset">重置</a-button>
          <a-button type="primary" style="margin-left:5px;" @click="handleExeclOut">一键导出</a-button>
          <a-button type="primary" style="margin-left:5px;" @click="handleDownloadFj">一键下载附件</a-button>
          
        </a-form-item>
      </a-form>
      <a-table
        :columns="columns"
        row-key="id"
        :data-source="tableData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange">
          <template slot="status" slot-scope="text">
            {{text | formateStatus()}}
          </template>
          <template slot="time" slot-scope="text">
            {{text | formateDate()}}
          </template>
          <template slot="timeWithNoDetail" slot-scope="text">
            {{text | formateDate(true)}}
          </template>
          <template slot="operate" slot-scope="text, item">
            <router-link :to="{path: '/index/taskDetail', query: {id: item.id}}">查看</router-link>
          </template>
        </a-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { taskList, getUserByRole } from '@/utils/api'
import moment from 'moment'
const columns = [
  {
    title: '订单号',
    dataIndex: 'order_no'
  },
  {
    title: '任务名称',
    dataIndex: 'task_name'
  },
  {
    title: '开始时间',
    dataIndex: 'start_time',
    scopedSlots: { customRender: 'timeWithNoDetail' },
  },
  {
    title: '项目周期（天）',
    dataIndex: 'day'
  },
  {
    title: '项目服务费',
    dataIndex: 'task_money'
  },
  {
    title: '创建时间',
    dataIndex: 'add_time',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'operate',
    scopedSlots: { customRender: 'operate' },
  }
]
export default {
  name: 'TaskList',
  data() {
    return {
      form: this.$form.createForm(this),
      tableParams: {},
      columns,
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showTotal: total => `共${total}条数据`
      },
      loading: false,
      userRole1: [],
      userRole2: [],
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  filters: {
    formateDate(val, noDetail) {
      if (val) {
        const date = new Date(parseInt(val) * 1000)
        const y = date.getFullYear()
        const m = date.getMonth() + 1
        const d = date.getDate()
        const h = date.getHours()
        const i = date.getMinutes()
        const s = date.getSeconds()
        return y + '-'
              + (m < 10 ? '0' + m : m) + '-'
              + (d < 10 ? '0' + d : d) +
              (noDetail ? '' : (' '
              + (h < 10 ? '0' + h : h) + ':'
              + (i < 10 ? '0' + i : i) + ':'
              + (s < 10 ? '0' + s : s)
              ))
      }
      return ''
    },
    formateStatus(val) {
      let str = ''
      switch (val) {
        case 0:
          str = '已创建项目'
          break;
        case 1:
          str = '已接项目'
          break;
        case 2:
          str = '已完成项目'
          break;
        case 3:
          str = '公司已支付'
          break;
        case 4:
          str = '服务商已收款'
          break;
        case 5:
          str = '公司已申请开票'
          break;
        case 6:
          str = '服务商已开票'
          break;
        case 7:
          str = '服务已付款到分包商'
          break;
        case 8:
          str = '分包商已收款并开票'
          break;
      }
      return str
    }
  },
  mounted() {
    this.getUserByRoleData(1)
    this.getUserByRoleData(2)
    this.getTableData()
  },
  methods: {
    getUserByRoleData(role_id) {
      getUserByRole({
        role_id
      }).then((res)=> {
        if (role_id == 1) {
          this.userRole1 = res.info
        } else if (role_id == 2) {
          this.userRole2 = res.info
        }
      })
    },
    handleExeclOut() {
      const url = '/home/task_list?is_excel=1'
      let params = ''
      for (const key in this.tableParams) {
        if (Object.hasOwnProperty.call(this.tableParams, key)) {
          const item = this.tableParams[key]
          if (item !== undefined) {
            params += '&' + key + '=' + item
          }
        }
      }
      window.open(url + params)
    },
    handleDownloadFj() {
      const url = '/home/task_list?is_excel=2'
      let params = ''
      for (const key in this.tableParams) {
        if (Object.hasOwnProperty.call(this.tableParams, key)) {
          const item = this.tableParams[key]
          if (item !== undefined) {
            params += '&' + key + '=' + item
          }
        }
      }
      window.open(url + params)
    },
    handleSearch() {
      this.form.validateFields((err, values) => {
        console.log(values.timeArr)
        if (values.timeArr) {
          values.start_time = moment(values.timeArr[0]).format('YYYY-MM-DD')
          values.end_time = moment(values.timeArr[1]).format('YYYY-MM-DD')
        }
        delete values.timeArr
        this.tableParams = { ...values }
        const pagination = { ...this.pagination }
        pagination.current = 1,
        this.pagination = pagination
        this.getTableData()
      })
    },
    handleReset() {
      this.form.setFieldsValue({
        task_name: '',
        company_name: '',
        status: '',
        timeArr: undefined
      })
      this.handleSearch()
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getTableData()
    },
    getTableData() {
      this.loading = true
      const pagination = { ...this.pagination }
      taskList({
        pageCount: pagination.pageSize,
        page: pagination.current,
        ...this.tableParams
      }).then(res => {
        if (pagination.current == 1){
          pagination.total = res.count
          this.pagination = pagination
        }
        this.tableData = res.task
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px 24px;
}
</style>
